<template>
  <div
    class="columns is-marginless has-padding-50 has-padding-bottom-400 is-multiline"
  >
    <div class="column is-12">
      <card>
        <template slot="header">
          <p class="card-header-title">
            <strong>FixedVault™</strong>
          </p>
        </template>
        <template slot="body">
          <fixed-vault-table />
        </template>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedVault",
  components: {
    "fixed-vault-table": () => import("@shared/vault/_fixedVaultTable.vue")
  }
};
</script>
